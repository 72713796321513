import React from 'react';
import styles from './Projects.css';
import ProjectCard from './ProjectCard';

const Projects = () => {
    const projects = [
        {
            id: 1,
            name: 'Avesdo Mobile Application',
            stacks : 'React Native, Node.JS, AWS S3',
            link: 'https://avesdo.com/',
            linkName: 'Avesdo Website',
            description: 'A mobile native application developed for Avesdo to facilitate the process of buying and selling real estate properties.',
            imageUrl: 'https://i.ibb.co/SfRPr4r/ola-chat.jpg',
        },
        {
            id: 2,
            name: 'Squamish Real Estate',
            stacks : 'Django, Python',
            link: 'https://squamish.realestate/',
            linkName: 'Squmish Real Estate Website',
            description: 'School sponsored project to add new functionalities and remove bugs from the existing website.',
            imageUrl: 'https://ibb.co/Tg4GLCx',
        },
        {
            id: 3,
            name: 'Command-Line HTTP Server',
            stacks : 'C program',
            link: 'https://github.com/batchansaa/COMP4981_Assignment1_HTTP',
            linkName: 'Github Link',
            description: 'Group Assignment to create a command-line HTTP server using C program.',
            imageUrl: 'https://ibb.co/Tg4GLCx',
        },

        {
            id: 4,
            name: 'How Many Fingers?',
            stacks : 'OpenCV, Mediapipe, Python',
            link: 'https://github.com/batchansaa/handTracker',
            linkName: 'Github Link',
            description: 'Personal project to count the number of fingers using OpenCV and Mediapipe.',
            imageUrl: 'https://ibb.co/Tg4GLCx',
        },

        {
            id: 5,
            name: 'UrbaCrop',
            stacks : 'Bootstrap, MongoDB, Heroku, Google Maps API',
            link: 'https://github.com/martigatchev/COMP-2800-Team-DTC-09-UrbaCrop',
            linkName: 'Github Link',
            description: 'Group project for Vancouverites to find community gardens.',
            imageUrl: 'https://ibb.co/Tg4GLCx',
        },

        {
            id: 6,
            name: 'BeatCST',
            stacks : 'Bootstrap, Firebase, Google Calendars API',
            link: 'https://github.com/paulyeon/team46_BeatCST',
            linkName: 'Github Link',
            description: 'Personal project to count the number of fingers using OpenCV and Mediapipe.',
            imageUrl: 'https://ibb.co/Tg4GLCx',
        },

        {
            id: 7,
            name: 'Namu',
            stacks : 'PyGame, Python',
            link: 'https://devpost.com/software/namu',
            linkName: 'Devpost Link',
            description: 'PyGame project developed to educate the players on ocean ecosystem.',
            imageUrl: 'https://ibb.co/Tg4GLCx',
        },
        
    ];

    return (
        <div>
            <h1 className="projects-title">Projects</h1>
            <div className="card-container">
                {projects.map((project) => (
                    <ProjectCard
                        key={project.id}
                        projectImage = {project.imageUrl}
                        projectName = {project.name}
                        projectLinkName = {project.linkName}
                        projectLink = {project.link}
                        projectStacks = {project.stacks}
                        projectDescription = {project.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default Projects;