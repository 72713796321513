import React from "react";
import BlushLogo from "../../img/logo-blush.png";
import NoBlushLogo from "../../img/logo-no-blush.png";
import ByeByeGif from "../../img/bye-bye.gif";
import EmailArrow from "../../img/email-arrow.svg";
import styles from "./ContactMe.css";

const ContactMe = () => {
    const sendEmail = () => {
        // Logic to send email goes here
        const email = "bchansaa@gmail.com";
        const subject = "Contact Me";
        const body = "Hello, I would like to get in touch with you.";
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
            subject
        )}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    return (
        <div className="contact-me-main">
            <h1 className="contact-me-title">Contact Me</h1>

            <p>Feel free to reach out to me by email.</p>
            <div className="send-email-button">
                <p>Send me an e-mail</p>
                <img src={EmailArrow} className="email-arrow" alt="email-arrow" onClick={sendEmail} />
            </div>
            <p>Or through LinkedIn on the side nav!</p>
            <p>Thanks for stopping by!</p>
            <img src={ByeByeGif} className="bye-bye-gif" alt="bye-bye" />
        </div>
    );
};

export default ContactMe;
