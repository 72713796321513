import {React, useEffect, useRef, useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NoBlushLogo from '../img/logo-no-blush.png';
import BlushLogo from '../img/logo-blush.png';
import SideNav from '../components/SideNav';
import { useInView } from 'react-intersection-observer';
import styles from './LandingPage.css';
import AboutMe from '../components/AboutMe';
import Experience from '../components/Experience';
import Projects from '../components/Projects';
import ContactMe from '../components/ContactMe';
import useScrollSnap from "react-use-scroll-snap";



const LandingPage = () => {
    const [activeSection, setActiveSection] = useState('');
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const firstClassName = entry.target.className.split(' ')[0];
            setActiveSection(firstClassName);
          }
        });
      },
      { threshold: 0.5 }
    );

    const sections = ref.current.querySelectorAll('section');
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div className="LandingPage">
      <div className='side-nav-container'>
        <SideNav activeSection={activeSection} />
      </div>
      <div className='main-container' ref={ref}>
        <section className={`first-section section ${activeSection === 'first-section' ? 'active' : ''}`}>
          <AboutMe />
        </section>
        <section className={`second-section section ${activeSection === 'second-section' ? 'active' : ''}`}>
          <Experience />
        </section>
        <section className={`third-section section ${activeSection === 'third-section' ? 'active' : ''}`}>
          <Projects />
        </section>
        <section className={`fourth-section section ${activeSection === 'fourth-section' ? 'active' : ''}`}>
          <ContactMe />
        </section>
      </div>
    </div>
  );
};

export default LandingPage;