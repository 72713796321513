import { React } from "react";
import styles from "./AboutMe.css";
import { useInView } from "react-intersection-observer";
import DownArrow from "../../img/down-arrow.svg";
import { TypeAnimation } from "react-type-animation";

const AboutMe = () => {
    // const { ref, inView } = useInView({
    //     threshold: 0,
    //   });

    return (
        <div className="main-container">
            <div className="text-container">
                <h1 className="visible-text">Hi,</h1>
                <TypeAnimation
                    sequence={[
                        "I'm Chansaa. :)",
                        1500,
                        "I'm a Student.",
                        1000,
                        "I'm a Developer.",
                        1000,
                        "I'm a Designer.",
                        1000,
                    ]}
                    speed={50}
                    repeat={Infinity}
                    style={{ fontSize: "2em" }}
                />
            </div>
        </div>
    );
};

export default AboutMe;
