import {React} from 'react';
import styles from './Experience.css';

const Experience = () => {
    return (
        <div className="main-container">
            <div className="experience-title">
                <h1>Experience</h1>
            </div>
            <div className="experience-content">
                <div className="experience-content-item">
                    <h2>Software Developer Intern</h2>
                    <p>And Systems</p>
                    <p>June 2021 - August 2021</p>
                    <p>Ulaanbaatar, Mongolia</p>

                    <div>
                        <h3>Responsibilities</h3>
                        <ul>
                            <li>Worked with a fellow intern to compare the performance of AWS ElasticSearch to Grafana</li>
                            <li>Worked on dashboarding and data processing for AWS ElasticSearch</li>
                        </ul>
                    </div>
                </div>
                <div className="experience-content-item">
                    <h2>Software Developer Intern</h2>
                    <p>Model Reno</p>
                    <p>January 2022 - July 2022</p>
                    <p>Vancouver, BC</p>

                    <div>
                        <h3>Responsibilities</h3>
                        <ul>
                        <li>Tasked with building components using React to be published on the website</li>
                        <li>Collaborate with teammates to coordinate task using Sprint planning</li>
                        <li>Build Node.JS endpoints to ensure full functionality of the back-end and front-end</li>
                        <li>Designing and publishing new components as required by the sales team</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Experience;