import React from 'react';
import styles from './ProjectCard.css';


const ProjectCard = ({projectImage, projectName, projectLinkName, projectLink, projectStacks, projectDescription}) => {

    return (
        <div className="project">
            <div className="overlay">
                <h1 className="project-name">{projectName}</h1>
                <a className="project-link" href={projectLink}>{projectLinkName}</a>
            </div>
            <div className="project-details">
                <p className="project-stack">{projectStacks}</p>
                <p className="project-description">{projectDescription}</p>
            </div>
        </div>
    );
};

export default ProjectCard;