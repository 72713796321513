import {React, useState} from 'react';
import BlushLogo from '../../img/logo-blush.png';
import LinkedinLogo from '../../img/linkedin-logo.svg';
import GithubLogo from '../../img/github-logo.svg';
import styles from './SideNav.css';
import { IntersectionObserver } from 'react-intersection-observer';


const SideNav = ({activeSection}) => {

    console.log(activeSection);


    return (
        <div className="side-nav">
            <div className="logo-container">
                {/* Personal Logo */}
                <img src={BlushLogo} className="side-nav-logo" alt="Personal Logo" />
                <p className='side-nav-name'>[Chansaa]</p>
            </div>

            <div className="social-links-container">
                {/* LinkedIn Logo */}
                <a href="https://www.linkedin.com/in/chansaa-batzorig/">
                    <img src={LinkedinLogo} className="side-nav-link-logo linkedin-logo" alt="LinkedIn" />
                </a>
                {/* GitHub Logo */}
                <a href="https://github.com/batchansaa">
                    <img src={GithubLogo} className="side-nav-link-logo github-logo" alt="GitHub" />
                </a>
            </div>
            <div className="menu-container">
                <ul className='menu'>
                    <li className={activeSection === 'first-section' ? 'active' : ''}>Hello</li>
                    <li className={activeSection === 'second-section' ? 'active' : ''}>Experience</li>
                    <li className={activeSection === 'third-section' ? 'active' : ''}>Projects</li>
                    <li className={activeSection === 'fourth-section' ? 'active' : ''}>Contact Me</li>
                </ul>
            </div>
        </div>
    );
};

export default SideNav;
